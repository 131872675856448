@import "../../../styles/variables.module";
.offer {
  @include extra_small {
    padding-top: 30px;
  }
  &.pt0 {
    .forNew {
      &.orderBanner {
        margin-bottom: 80px;
        margin-top: 50px;
        padding-top: 40px;
        padding-bottom: 20px;
        @include mobile_device {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
  .forNew {
    background-color: $theme-color;
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 80px;
    @include mobile_device {
      margin-bottom: 60px;
      padding-top: 0;
    }
    .title {
      font-weight: bold;
      font-size: 40px;
      line-height: 44px;
      color: $white-color;
      position: relative;
      @include extra_large {
        font-size: 34px;
        line-height: 42px;
      }
      @include large_device {
        font-size: 30px;
      }
    }
    &.orderBanner {
      background-color: $theme-color;
      margin-bottom: 50px;
      margin-top: 50px;
      @include large_device {
        margin-bottom: 80px;
        margin-top: 80px;
        p {
          width: 100%;
        }
        .mt5 {
          margin-top: 2rem !important;
        }
      }
      @include mobile_device {
        margin-bottom: 60px;
        margin-top: 60px;
        padding-top: 0;
        padding-bottom: 0;
      }
      &::before,
      &::after {
        background-image: url("../../" + $bg-base-path + "/bannerBorder.svg");
      }
      .sectionTitle {
        color: $white-color;
        margin-bottom: 8px;
        p {
          color: $white-color;
        }
      }
      .desc {
        color: $white-color;
        width: 70%;
        margin: 0 auto 32px;
        @include mobile_device {
          width: 100%;
        }
        p {
          color: $white-color;
          margin-bottom: 0;
        }
      }
      .btn {
        width: 240px;
      }
    }
    &::before,
    &::after {
      content: "";
      background-image: url("../../" + $bg-base-path + "/bannerBorder.svg");
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      height: 85px;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &::before {
      top: -84px;
      @include mobile_device {
        top: -74px;
      }
    }
    &::after {
      bottom: -84px;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      @include mobile_device {
        bottom: -74px;
      }
    }
    .banner {
      position: relative;
      width: 143px;
      height: 143px;
      margin: 0 auto;
      animation: pulse 2s linear infinite;
      @include large_device {
        width: 128px;
        height: 128px;
      }
      @-webkit-keyframes pulse {
        0% {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
        50% {
          -webkit-transform: scale3d(1.05, 1.05, 1.05);
          transform: scale3d(1.05, 1.05, 1.05);
        }
        to {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }

      @keyframes pulse {
        0% {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
        50% {
          -webkit-transform: scale3d(1.05, 1.05, 1.05);
          transform: scale3d(1.05, 1.05, 1.05);
        }
        to {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
      .content {
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translate(-50%);
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        display: inline-block;
        line-height: normal;
        span {
          font-style: italic;
        }
      }
    }
    .text {
      font-weight: 700;
      font-size: 30px;
      margin-top: 24px;
      text-transform: capitalize;
      text-align: center;
      color: $white-color;
      @include extra_large {
        font-size: 16px;
      }
    }
    .inputWrpper {
      width: 80%;
      margin: 32px auto 0;
      @include large_device {
        margin-top: 30px;
      }
      @include medium_device {
        width: 100%;
      }
      .inputGroup {
        @include large_device {
          justify-content: center;
        }
        @include medium_device {
          flex-direction: column;
        }
        .emailInput {
          width: calc(100% - 170px);
          @include large_device {
            width: calc(100% - 200px);
          }
          @include medium_device {
            width: 100%;
          }
          .formControl {
            padding: 6px 15px;
            height: 50px;
            background: #f2f6f8;
            border: none;
            color: $black-color;
            font-size: 16px;
            border-radius: $border-radius !important;
            width: 100%;
            &:focus {
              outline: none;
            }
          }
        }
        .btn {
          margin-left: 20px !important;
          width: 250px;
          height: 50px;
          @include extra_large {
            width: auto;
            height: auto;
            align-self: baseline;
          }
          @include medium_device {
            margin-left: 0 !important;
            width: 100%;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
